import React from 'react'
import { useCoreData } from '../utils/useCoreData'
import { Container } from './Container'
import { FullPage } from './FullPage'
import { NewsletterSignUpForm } from './NewsletterSignUpForm'

export function NewsletterSignUpSection (props) {
  const {
    api: {
      data: { miscellaneous }
    }
  } = useCoreData()

  if (!miscellaneous) return null

  return (
    <FullPage>
      <Container {...props}>
        <NewsletterSignUpForm data={miscellaneous} />
      </Container>
    </FullPage>
  )
}

export default NewsletterSignUpSection
