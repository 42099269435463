import { Box, makeStyles } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { colors, respSectionPadding } from '../utils/theme'
import { Headline } from './Headline'
import { useMobile } from '../utils/useMobile'
import { getWindow } from '../utils/getWindow'
import { cx } from '../utils/cx'
import { richText } from '../utils/richText'

const withBlackFont = {
  '& span': {
    color: colors.black
  }
}

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 0
  },
  show: {
    display: 'block !important'
  },
  countryText: {
    display: 'none',
    fontWeight: 600,
    color: colors.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2
  },
  defaultCountry: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      '& span': {
        display: 'block !important'
      }
    }
  },
  image: {
    maxWidth: '100%'
  },
  country1: {
    gridColumn: '1 / 11',
    gridRow: '1 / 17',
    backgroundColor: colors.red
  },
  country2: {
    gridColumn: '15 / 21',
    gridRow: '16 / 18',
    backgroundColor: colors.green
  },
  country3: {
    gridColumn: '8 / 11',
    gridRow: '18 / 21',
    backgroundColor: colors.yellow,
    ...withBlackFont
  },
  country4: {
    gridColumn: '22 / 26',
    gridRow: '19 / 21',
    backgroundColor: colors.red,
    ...withBlackFont
  },
  country5: {
    gridColumn: '6 / 8',
    gridRow: '22 / 24',
    backgroundColor: colors.lightGrey,
    ...withBlackFont
  },
  country6: {
    gridColumn: '10 / 13',
    gridRow: '22 / 24',
    backgroundColor: colors.darkBlue,
    ...withBlackFont
  },
  country7: {
    gridColumn: '7 / 10',
    gridRow: '25 / 27',
    backgroundColor: colors.green,
    ...withBlackFont
  },
  country8: {
    gridColumn: '11 / 14',
    gridRow: '27 / 28',
    backgroundColor: colors.red,
    ...withBlackFont
  },
  country9: {
    gridColumn: '13 / 16',
    gridRow: '29 / 31',
    backgroundColor: colors.yellow,
    ...withBlackFont
  },
  // ONE COUNTRY
  country10: {
    gridColumn: '15 / 26',
    gridRow: '32 / 37',
    backgroundColor: colors.lightGrey,
    ...withBlackFont
  },
  country11: {
    gridColumn: '15 / 21',
    gridRow: '37 / 40',
    backgroundColor: colors.lightGrey,
    ...withBlackFont
  },
  //
  country12: {
    gridColumn: '27 / 38',
    gridRow: '30 / 37',
    backgroundColor: colors.darkBlue
  },
  country13: {
    gridColumn: '15 / 21',
    gridRow: '41 / 45',
    backgroundColor: colors.red,
    ...withBlackFont
  },
  // ONE COUNTRY
  country14: {
    gridColumn: '22 / 39',
    gridRow: '38 / 45',
    backgroundColor: colors.green
  },
  country15: {
    gridColumn: '39 / 55',
    gridRow: '35 / 51',
    backgroundColor: colors.green
  },
  country16: {
    gridColumn: '39 / 46',
    gridRow: '51 / 61',
    backgroundColor: colors.green
  },
  //
  // ONE COUNTRY
  country17: {
    gridColumn: '15 / 24',
    gridRow: '46 / 53',
    backgroundColor: colors.darkBlue
  },
  country18: {
    gridColumn: '24 / 27',
    gridRow: '46 / 50',
    backgroundColor: colors.darkBlue
  },
  //
  // ONE COUNTRY
  country19: {
    gridColumn: '28 / 38',
    gridRow: '46 / 53',
    backgroundColor: colors.red
  },
  country20: {
    gridColumn: '28 / 31',
    gridRow: '53 / 61',
    backgroundColor: colors.red
  },
  //
  country21: {
    gridColumn: '32 / 38',
    gridRow: '54 / 61',
    backgroundColor: colors.lightGrey,
    ...withBlackFont
  },
  country22: {
    gridColumn: '25 / 27',
    gridRow: '51 / 91',
    backgroundColor: colors.yellow,
    ...withBlackFont
  },
  country23: {
    gridColumn: '28 / 38',
    gridRow: '62 / 89',
    backgroundColor: colors.lightBlue
  },
  country24: {
    gridColumn: '39 / 43',
    gridRow: '62 / 66',
    backgroundColor: colors.yellow,
    ...withBlackFont
  },
  country25: {
    gridColumn: '17 / 21',
    gridRow: '19 / 21',
    backgroundColor: colors.lightBlue,
    ...withBlackFont
  },
  headline: {
    '& p:not(:last-of-type)': {
      fontStyle: 'italic'
    },
    '& p:last-of-type': {
      fontWeight: 600,
      marginTop: '8px'
    }
  }
}))

const countries = [
  ['mexico', [1]],
  ['cuba', [2]],
  ['guatemala', [3]],
  ['republica_dominicana', [4]],
  ['el_salvador', [5]],
  ['honduras', [6]],
  ['nicaragua', [7]],
  ['costa_rica', [8]],
  ['panama', [9]],
  ['colombia', [10, 11], 0],
  ['venezuela', [12]],
  ['equador', [13]],
  ['brasil', [14, 15, 16], 1],
  ['peru', [17, 18], 0],
  ['bolivia', [19, 20], 0],
  ['paraguay', [21]],
  ['chile', [22]],
  ['argentina', [23]],
  ['uruguay', [24]],
  ['haiti', [25]]
]

export function Map ({ data }) {
  const classes = useStyles()
  const mobile = useMobile()
  const [content, setContent] = useState(null)
  const dataRef = useRef(null)
  return (
    <>
      {mobile && (
        <Headline variant='h5' className={classes.headline}>
          {richText(data.main_sentence)}
        </Headline>
      )}
      <Box
        display='grid'
        gridTemplateColumns='repeat(60, minmax(5px,8px))'
        gridTemplateRows='repeat(100, minmax(5px,8px))'
        flexBasis='62%'
        pt={mobile ? respSectionPadding(mobile) + 'px' : ''}
        pb={mobile ? '7%' : ''}
        px='14px'
        minWidth='300px'
        minHeight='700px'
      >
        {countries.map(([id, blocks, mainIndex], j) =>
          blocks.map((block, i) => (
            <div
              key={`${id}_${block}`}
              className={cx(classes[`country${block}`], classes.defaultCountry)}
              onClick={() => {
                const shouldScroll = content !== id
                setContent(prev => (prev !== id ? id : null))
                if (mobile) {
                  const win = getWindow()
                  if (win && dataRef.current && shouldScroll) {
                    const targetScrollTo = dataRef.current.offsetTop - 50
                    const int = setInterval(() => {
                      const currentScroll = win.scrollY
                      if (currentScroll < targetScrollTo) {
                        win.scrollTo(0, currentScroll + 10)
                      } else {
                        clearInterval(int)
                      }
                    }, 3)
                  }
                }
              }}
            >
              {(mainIndex === undefined || mainIndex === i) && (
                <span
                  className={cx(
                    classes.countryText,
                    content === id && classes.show
                  )}
                >
                  {data[`${id}_short_name`]}
                </span>
              )}
            </div>
          ))
        )}
      </Box>
      {(content || !mobile) && (
        <Box
          textAlign={mobile ? 'left' : 'right'}
          flex={1}
          ref={el => (dataRef.current = el)}
        >
          {content ? (
            <ContryData id={content} data={data} />
          ) : (
            <Headline variant='h5' className={classes.headline}>
              {richText(data.main_sentence)}
            </Headline>
          )}
        </Box>
      )}
    </>
  )
}

function ContryData ({ id, data }) {
  const classes = useStyles()
  const nameTitle = data.official_name
  const capitalTitle = data.capital
  const populationTitle = data.population
  const symbolTitle = data.national_symbol
  const curiosityTitle = data.curiosity
  const name = data[`${id}_name`]
  const population = data[`${id}_population`]
  const curiosity = data[`${id}_curiosity`]
  const symbol = data[`${id}_symbol`]
  const capital = data[`${id}_capital`]
  return (
    <Box fontFamily='Bodoni Moda' minWidth='300px'>
      <img
        className={classes.image}
        alt={name}
        src={`/images/countries/${id.toUpperCase().replace('_', ' ')}.jpg`}
      />
      <h4 className={classes.title}>{nameTitle}:</h4>
      <p>{name}</p>
      <h4 className={classes.title}>{capitalTitle}:</h4>
      <p>{capital}</p>
      <h4 className={classes.title}>{populationTitle}:</h4>
      <p>{population}</p>
      <h4 className={classes.title}>{symbolTitle}:</h4>
      <p>{symbol}</p>
      <h4 className={classes.title}>{curiosityTitle}:</h4>
      <p>{curiosity}</p>
    </Box>
  )
}
