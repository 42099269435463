import React from 'react'
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import { Container } from '../components/Container'
import { Map } from '../components/Map'
import { Page } from '../components/Page'
import { useMediumScreen } from '../utils/useMediumScreen'
import { respSectionPadding } from '../utils/theme'
import { useMobile } from '../utils/useMobile'
import { Loader } from '../components/Loader'
import { richText } from '../utils/richText'
import { NewsletterSignUpSection } from '../components/NewsletterSignUpSection'
import { Divider } from '../components/Divider'
import { useCoreData } from '../utils/useCoreData'

const useStyles = makeStyles(() => ({
  withStrong: {
    '& strong': {
      fontWeight: 600,
      display: 'block'
    }
  }
}))

export default function Contact () {
  return (
    <Page>
      <ContactContent />
    </Page>
  )
}

function ContactContent () {
  const classes = useStyles()
  const mobile = useMobile()
  const mediumScreen = useMediumScreen()

  const {
    api: {
      data: { contact }
    }
  } = useCoreData()

  if (!contact) return <Loader />

  return (
    <div>
      <Container withToolbar>
        <Box
          display='flex'
          width='100%'
          justifyContent='space-between'
          pt={mediumScreen ? 0 : '40px'}
          flexWrap='wrap'
        >
          <Box flexBasis='30%' flexGrow={1}>
            <Typography variant='h3' classes={{ root: classes.withStrong }}>
              {richText(contact.want_to_talk_to_us)}
            </Typography>
            <form
              target='_blank'
              action='https://formsubmit.co/2354fec7458fbcb823849c7368af8649'
              method='POST'
            >
              <Box mt='56px'>
                <Box mt='30px' width='100%'>
                  <TextField
                    name='Name'
                    fullWidth
                    label={contact.form_name}
                    required
                  />
                </Box>
                <Box mt='30px' width='100%'>
                  <TextField
                    name='Email'
                    fullWidth
                    label={contact.form_email}
                    required
                  />
                </Box>
                <Box mt='30px' width='100%'>
                  <TextField
                    name='Organisation'
                    fullWidth
                    label={contact.form_organisation}
                  />
                </Box>
                <Box mt='60px' width='100%'>
                  <TextField
                    name='Comment'
                    label={contact.form_comment}
                    rows={mediumScreen ? 5 : 10}
                    variant='outlined'
                    multiline
                    fullWidth
                    required
                  />
                </Box>
                <Box width='fit-content' ml='auto' mt='30px'>
                  <Button type='submit'>{contact.form_send}</Button>
                </Box>
              </Box>
            </form>
          </Box>
          <Box
            flexBasis={mobile ? '100%' : '65%'}
            display='flex'
            flexDirection={mobile ? 'column' : 'row'}
            py={mobile ? respSectionPadding(mobile) + 'px' : ''}
            height='fit-content'
            maxWidth='100%'
          >
            <Map data={contact} />
          </Box>
        </Box>
      </Container>
      <Container unwrap={mobile}>
        <Divider condensed />
        <NewsletterSignUpSection withBottomPadding />
      </Container>
    </div>
  )
}
