import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { cx } from '../utils/cx'

const useStyles = makeStyles(() => ({
  font: {
    fontFamily: 'Bodoni Moda'
  },
  bold: {
    fontWeight: 600
  },
  semibold: {
    fontWeight: 600
  },
  condensed: {
    lineHeight: 1
  }
}))

export function Headline ({
  children,
  bold,
  semibold,
  condensed,
  className,
  ...props
}) {
  const classes = useStyles()

  return (
    <Typography
      classes={{
        root: cx(
          classes.font,
          bold && classes.bold,
          condensed && classes.condensed,
          semibold && classes.semibold,
          className
        )
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}
